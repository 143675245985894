import React from "react"
import styled from "styled-components"
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


// Imports av font awesome ikoner skal skje i lqyout.js som alle henter, så alle sider har dem.
// se https://github.com/FortAwesome/react-fontawesome#build-a-library-to-reference-icons-throughout-your-app-more-conveniently for mer info
const BlockContent = require('@sanity/block-content-to-react')




const EmployeeDirectory = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: solid;
  border-color: white;
  padding: 0.5em;
`
// bilder er 0px uten denne, ukjent hvorfor
// man skal ikke trenge denne, vi har noe feil enten:
// i graphql query eller i komponenten
const ImageContainer = styled.div`

`

const EmployeeBio = styled.div`
  border: solid;
  border-color: green;

`


const Employees = ({ props }) => {
  return (
    <div className='tile is-6 '>
    <EmployeeDirectory>
      <p>{props.name}</p>
      <FontAwesomeIcon icon='stroopwafel'/>
      <FontAwesomeIcon icon={['far', 'stroopwafel']} />
      <button class="button is-danger">Danger</button>
      <ImageContainer>
        <Img fluid={props.mainImage.asset.fluid}
          alt={props.mainImage.alt}
        />
        <EmployeeBio>
     
          <BlockContent blocks={props._rawBio} />,
       </EmployeeBio>
      </ImageContainer>
  

    </EmployeeDirectory>
    </div>
  )

}
export default Employees