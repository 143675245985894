import Employees from '../components/employees'
import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"



export const query = graphql`
query AllPersonQuery {
  posts: allSanityPerson {
    edges {
      node {
        id
        name
        slug {
          current
        }
        _rawBio
        mainImage {
        alt
        asset {
      fluid(maxWidth: 1080) {
        ...GatsbySanityImageFluid
      }
  }
}
        bio {
          _key
        }
      }
    }
  }
}

`

//Alle nodes bør mappes, se eksempel på https://github.com/sanity-io/example-company-website-gatsby-sanity-combo/blob/5c0c29b51513b0d8069cf5eb759d05bb55902241/web/src/lib/helpers.js#L7
const IndexPage = props => {
    const { data, errors } = props
  
    if (errors) {
      return (
        <Layout>
          <h1>
            There be errors (bruk heller GraphyQlErrorList komponent eller noe
            slik)
          </h1>
        </Layout>
      )
    }
  
    // Fjerna mainen som var under for å få vekk den rosa (UU++)
    return (
      <Layout>
        <SEO title="Ansatte" />
  
        <h1 className='title is-1'>Våre ansatte</h1>

        <p>Våre ansatt er:</p>
        {data.posts.edges.map(({ node }) => (
          <Employees props={node} key={node.id} />
        ))}
  
        <Link to="/about/">Go to About</Link>
        <br></br>
        <Link to="/blog/">Go to blog</Link>
        <br />
        <a href='http://localhost:8000/___graphql'>GrahphiQL</a>
      </Layout>
    )
  }
  export default IndexPage